import { crypto } from '@/pages/iebdc/utils/crypto.js';

export const whiteList = [
  '/login',
  '/user/updatePassword',
  '/user/getByLoginName',
  '/api/appointment/getAppointmentSortList',
  '/api/appointment/addNewAppointmentInfo',
  '/api/appointment/exportAppointmentInfo',
  '/api/appointment/getAppointmentInfoByCode',
  '/api/appointment/getAppointmentPgaeList',
  '/api/authorize/authorizeBankInspectList',
  '/api/authorize/authorizeBankOneInspect',
  '/api/authorize/authorizeBankUserGet',
  '/api/authorize/authorizeExcelToUser',
  '/buildingController/getDcbHById',
  '/buildingController/getDcbHs',
  '/buildingController/getDcbHsByLjzh',
  '/buildingController/getDcbZs',
  '/checkCertifyController/changeStatusByCxbh',
  '/checkCertifyController/exportExcell',
  '/checkCertifyController/getCertifiesTureOrNot',
  '/checkCertifyController/getCheckCertifies',
  '/checkCertifyController/getCheckCertifiesByQuery',
  '/checkCertifyController/getDetailsByCxbh',
  '/checkCertifyController/getJtjcxByQuery',
  '/dataExchangeController/checkBdcqxxByZh',
  '/dataExchangeController/checkCertifyInspect',
  '/dataExchangeController/geBdcxxByDyzmh',
  // '/dataExchangeController/getBdcxxByBdcqzh',
  '/dataExchangeController/getBdcxxByDyh',
  '/dataExchangeController/getBdcxxByHtbh',
  '/dataExchangeController/getBdcxxBySfzMc',
  '/dataExchangeController/getBdcxxByYgzmh',
  '/dataExchangeController/getBdcxxZddm',
  '/dataExchangeController/getBdcxxZddmFlushRedis',
  '/dataExchangeController/getFwxxByZddm',
  '/dataExchangeController/getQlrYwrStatusByZh',
  '/dataExchangeController/getTdBdcxxByBdcqzh',
  '/dataExchangeController/getYwzt',
  '/api/drools/getGroupByYwh',
  '/evaluateController/findEvaluatePageList',
  '/api/exchange/downloadCeritificate',
  '/api/exchange/getCertificateByBdcdyh',
  '/api/exchange/queryCeritificateList',
  '/api/exchange/verifyCeritificate',
  '/api/express/findByYwhAndPostType',
  '/api/express/getPageList',
  '/api/express/saveExpressInfo',
  '/fileUploadController/getAppPicture',
  '/fileUploadController/getUploadFiles',
  '/fileUploadController/getUploadFilesCurrent',
  '/fileUploadController/saveAppPicture',
  '/fileUploadController/saveFileUploadInfo',
  '/InquiryRecordController/getInquiryRecordByYwh',
  '/InquiryRecordController/getMainInquiryRecordByYwh',
  '/InquiryRecordController/save',
  '/api/nonTax/getNonTaxaListByLoginName',
  '/api/nonTax/syncPayinfo',
  '/registerController/getDcbZHByBdcdyh',
  '/registerController/getPassedApplies',
  '/registerController/getPreliminaries',
  '/registerController/getQlrsByZywh',
  '/registerController/getSqrRzztByWwywh',
  '/registerController/getYwrsByQlxxId',
  '/registerController/saveSqxx',
  '/registerController/sendMsgToQlrAndYwr',
  '/api/registerflow/addObligor',
  '/api/registerflow/addObligorBatch',
  '/api/registerflow/addRightHolder',
  '/api/registerflow/addRightHolderBatch',
  '/api/registerflow/editQlrFamilyList',
  '/api/registerflow/editYwrFamilyList',
  '/api/registerflow/exportProcessInfoExcel',
  '/api/registerflow/getByProcessInfoList',
  '/api/registerflow/getObligor',
  '/api/registerflow/getPassAppliesZipExport',
  '/api/registerflow/getProcessInfoByWwyyhAndTel',
  '/api/registerflow/getProcessInfoPageList',
  '/api/registerflow/getRightHolder',
  '/api/registerflow/judgeDuplicate',
  '/api/registerflow/modifyObligor',
  '/api/registerflow/modifyProcessInfo',
  '/api/registerflow/modifyRightHolder',
  '/api/registerflow/saveQlxxExMh',
  '/api/registerflow/copyDyrToZwr',
  '/sense/deviceRegister',
  '/sense/getInfoByDeviceBh',
  '/thirdServiceController/sendAuthCodeMsg',
  '/userInfoController/authInstitutionalUser',
  '/userInfoController/authUserinfo',
  '/userInfoController/checkInstitutionalUser',
  '/userInfoController/existsIntitutionalUserLoginName',
  '/userInfoController/existsUserByNameOrPhone',
  '/userInfoController/findCheckingIntitutionalUser',
  '/userInfoController/findInstitutionalUser',
  '/userInfoController/findUser',
  '/userInfoController/findUserName',
  '/userInfoController/findUserinfo',
  '/userInfoController/institutionalApprove',
  '/userInfoController/modifyUserinfoByTel',
  '/userInfoController/modifyUserinfoPhone',
  '/userInfoController/registerInstitutionalUser',
  '/userInfoController/registerUserinfo',
  '/userInfoController/setFileIds',
  '/userInfoController/updateFinacialInfo',
  '/userInfoController/updateInstitutionalUser',
  '/userInfoController/zddmApplyCheckByPage',
  '/userInfoController/zddmApplyGetById',
  '/userOperatorController/getAddressByUserId',
  '/userOperatorController/getBackstageDisplay',
  '/userOperatorController/getGuestNoteByCoupleQuery',
  '/userOperatorController/getGuestNoteBySingleQuery',
  '/userOperatorController/getGuestNoteList',
  '/userOperatorController/saveGuestNote',
  '/userOperatorController/saveOrUpdateAddress',
  '/userInfoController/inBlackList',
  '/api/doorToDoor/listByOptions',
  '/api/doorToDoor/initiate',
  '/digitalcert/api/download',
  '/digitalcert/api/verify',
  '/digitalcert/api/querycertificatelist',
  '/ems/getOrdersByUserId',
  '/payMutualController/saveDuplicatePayApply',
  '/realEstateQueryController/checkQszm',
  '/realEstateQueryController/getUncheckedQszmList',
  '/registerController/getPassedApplies',
  '/api/registerflow/addTzr',
  '/userInfoController/getBlackReasonByName',
  '/userInfoController/getAgentUserList',
  '/userInfoController/deleteUserinfoByLoginname',
  '/userInfoController/modifyUserinfoPwd',
  '/userInfoController/generateRandomValue',
  '/userInfoController/addToBlackList',
  '/userInfoController/updateAgent',
  '/userInfoController/checkSecretKey',
  '/userInfoController/getAgentUserByQyId',
  '/userInfoController/removeFromBlackList',
  '/userInfoController/getTwoDimensionalCode',
  '/userInfoController/addAgent',
  '/userInfoController/deleteInstitutionalUser',
  '/userOperatorController/getGuestNoteBySingleQuery',
  '/visualQueryRecordController/findById',
  '/userInfoController/updateDigitalAgent',
  '/registerController/savePrVerificationInfo',
  '/user/page',
  '/articleController/insertArticle',
  '/ca/addInstitutionalUser',
  '/visualQueryRecordController/save',
];

// 接口白名单
export const decryptWhiteList = [
  'userInfoController/findUser',
  'userInfoController/findUserinfo',
  'userInfoController/findInstitutionalUser',
  'userInfoController/findCheckingIntitutionalUser',
  'userInfoController/zddmApplyCheckByPage',
  'userOperatorController/getGuestNoteList',
  'userOperatorController/getGuestNoteByCoupleQuery',
  'userOperatorController/getBackstageDisplay',
  'userOperatorController/getAppointmentPgaeList',
  'userOperatorController/getAppointmentInfoByCode',
  'zwfw/zwAppLogin',
  'registerController/getPreliminaries',
  'RegisterController/getPassedApplies',
  'api/registerflow/getProcessInfo',
  'api/registerflow/getProcessInfoPageList',
  'api/registerflow/getByProcessInfoList',
  'api/registerflow/addObligor',
  'registerflow/getRightHolder',
  'api/drools/sceneGetByPage',
  'api/appointment/addNewAppointmentInfo',
  'api/appointment/getAppointmentInfoByCode',
  'api/appointment/getAppointmentPgaeList',
  'evaluateController/findEvaluatePageList',
  'dataExchangeController/checkCertifyInspect',
  'checkCertifyController/getCheckCertifies',
];

// 部分请求参数不加密接口
const notEncryptList = {
  '/checkCertifyController/changeStatusByCxbh': ['statusEnum'],
  '/checkCertifyController/exportExcell': ['status'],
  '/checkCertifyController/getCheckCertifies': ['checkCertifyTypeEnum'],
  '/checkCertifyController/getCheckCertifiesByQuery': ['checkCertifyTypeEnum', 'checkCertifyStatusEnum'],
  '/checkCertifyController/getJtjcxByQuery': ['status'],
  '/api/express/saveExpressInfo': ['postType'],
  '/faceverify/getFaceVerifyByPage': ['faceVerifyEnum'],
  '/fileUploadController/getAppPicture': ['fileEnum'],
  '/fileUploadController/saveAppPicture': ['fileEnum'],
  '/registerController/saveSqxx': ['bdclb'],
  '/api/registerflow/addObligor': ['familyRelationshipEnum'],
  '/api/registerflow/addObligorBatch': ['familyRelationshipEnum'],
  '/api/registerflow/addProcessInfo': ['bdclb'],
  '/api/registerflow/addRightHolderBatch': ['familyRelationshipEnum'],
  '/api/registerflow/editQlrFamilyList': ['familyRelationshipEnum'],
  '/api/registerflow/editYwrFamilyList': ['familyRelationshipEnum'],
  '/api/registerflow/exportProcessInfoExcel': ['step', 'stepList'],
  '/api/registerflow/getByProcessInfoList': ['step'],
  '/api/registerflow/getProcessInfoPageList': ['step', 'stepList'],
  '/api/registerflow/judgeDuplicate': ['familyRelationshipEnum'],
  '/api/registerflow/modifyObligor': ['familyRelationshipEnum'],
  '/api/registerflow/modifyProcessInfo': ['qzfs', 'familyRelationshipEnum'],
  '/userInfoController/findInstitutionalUser': ['black'],
  '/userInfoController/findUserinfo': ['black'],
  '/userInfoController/registerInstitutionalUser': ['userCategory'],
  '/userInfoController/registerUserinfo': ['userCategory'],
  '/front_verify': ['ssUrlEnum'],
};

export const encryptWhiteInfo = {
  '/login': ['password'],
  '/user/updatePassword': ['oriPassword', 'newPassword'],
  '/user/getByLoginName': ['password', 'confirmPsw'],
  '/api/appointment/addNewAppointmentInfo': ['yyyhmc', 'zjh', 'lxdh', 'yybsdtdz', 'bsdtdh'],
  '/api/appointment/exportAppointmentInfo': ['yyyhmc', 'zjh'],
  '/api/appointment/getAppointmentPgaeList': ['lxdh'],
  '/api/authorize/authorizeBankInspectList': ['name', 'zjh'],
  '/api/authorize/authorizeBankOneInspect': ['userName', 'zjh'],
  '/api/authorize/authorizeBankUserGet': ['name'],
  '/checkCertifyController/exportExcell': ['kfsmc', 'cxsqr', 'cxjg'],
  '/checkCertifyController/getCertifiesTureOrNot': ['qlrmc', 'qlrzjh', 'phone'],
  '/checkCertifyController/getCheckCertifies': ['loginName'],
  '/checkCertifyController/getCheckCertifiesByQuery': ['name', 'zjh'],
  '/checkCertifyController/getJtjcxByQuery': ['kfsmc', 'cxsqr'],
  '/dataExchangeController/checkBdcqxxByZh': ['qlrmc', 'qlrzjh', 'zl', 'zjh'],
  '/dataExchangeController/checkCertifyInspect': ['loginName', 'name', 'userName', 'phone', 'qlrmc', 'zjh'],
  // '/dataExchangeController/getBdcxxByBdcqzh': ['qlrmc', 'qlrzjh', 'bdcqzh', 'zjh', 'bdcdyh'],
  '/dataExchangeController/getBdcxxBySfzMc': ['qlrmc', 'qlrzjh'],
  '/dataExchangeController/getBdcxxByYgzmh': ['qlrmc', 'qlrzjh'],
  '/dataExchangeController/getQlrYwrStatusByZh': ['zh'],
  '/dataExchangeController/getTdBdcxxByBdcqzh': ['qlrmc', 'qlrzjh', 'bdcdyh', 'zjh', 'bdcqzh'],
  '/dataExchangeController/getYwzt': ['cxr'],
  '/api/exchange/downloadCeritificate': ['yhm', 'zh'],
  '/api/exchange/queryCeritificateList': ['yhm', 'zh', 'qlrmc', 'qlrzjh'],
  '/api/exchange/verifyCeritificate': ['zh', 'yhm', 'qlrmc', 'qlrzjh'],
  '/api/express/getPageList': ['senderName', 'senderPhone', 'receiverName', 'courier', 'trackingNum'],
  '/api/express/saveExpressInfo': [
    'senderName',
    'senderPhone',
    'senderAddr',
    'receiverName',
    'receiverPhone',
    'receiverAddr',
    'courier',
    'courierPhone',
    'signatory',
    'signatoryPhone',
  ],
  '/fileUploadController/saveFileUploadInfo': ['zjh', 'userName'],
  '/InquiryRecordController/save': ['xwr', 'contactPhone', 'signName', 'signIdCard', 'swqr'],
  '/api/nonTax/getNonTaxaListByLoginName': ['loginName'],
  '/api/nonTax/syncPayinfo': ['loginName'],
  '/registerController/getPassedApplies': ['qlrmc', 'ywrmc', 'qlrzjh', 'zl', 'bdcqzh'],
  '/registerController/getPreliminaries': ['qlrmc', 'ywrmc', 'qlrzjh', 'zl', 'bdcqzh'],
  '/registerController/saveSqxx': ['ygr', 'nsrsbh', 'fwsyqzh', 'zl', 'zddm', 'bdcqzh', 'tdsyqr', 'subFlowcode'],
  '/api/registerflow/addObligor': [
    'ywrmc',
    'zjh',
    'dh',
    'dz',
    'dlrmc',
    'dlrdh',
    'dlrzjh',
    'frmc',
    'frdh',
    'frzjh',
    'zl',
    'name',
    'nameUsedBefore',
    'zjh',
    'phone',
  ],
  '/api/registerflow/addObligorBatch': [
    'bdcdyhs',
    'ywrmc',
    'zjh',
    'dh',
    'dz',
    'dlrmc',
    'dlrdh',
    'dlrzjh',
    'frmc',
    'frdh',
    'frzjh',
    'zl',
    'name',
    'nameUsedBefore',
    'zjh',
    'phone',
  ],
  '/api/registerflow/addRightHolder': [
    'qlrmc',
    'zjh',
    'dh',
    'frmc',
    'frzjh',
    'frdh',
    'dz',
    'dlrmc',
    'dlrzjh',
    'dlrdh',
    'zl',
    'name',
    'nameUsedBefore',
    'zjh',
    'phone',
  ],
  '/api/registerflow/addRightHolderBatch': [
    'qlrmc',
    'zjh',
    'dh',
    'frmc',
    'frzjh',
    'frdh',
    'dz',
    'dlrmc',
    'dlrzjh',
    'dlrdh',
    'zl',
    'name',
    'nameUsedBefore',
    'zjh',
    'phone',
  ],
  '/api/registerflow/editQlrFamilyList': ['name', 'nameUsedBefore', 'zjh', 'phone'],
  '/api/registerflow/editYwrFamilyList': ['name', 'nameUsedBefore', 'zjh', 'phone'],
  '/api/registerflow/exportProcessInfoExcel': ['qlrmc', 'qlrzjh', 'zl', 'ywrmc'],
  '/api/registerflow/getByProcessInfoList': ['zl'],
  '/api/registerflow/getPassAppliesZipExport': ['qlrmc', 'qlrzjh', 'zl', 'ywrmc', 'bdcqzh', 'userName'],
  '/api/registerflow/getProcessInfoByWwyyhAndTel': ['lxdh'],
  '/api/registerflow/getProcessInfoPageList': ['qlrmc', 'ywrmc', 'qlrzjh', 'zl'],
  '/api/registerflow/judgeDuplicate': [
    'ywrmc',
    'qlrmc',
    'zjh',
    'dh',
    'frmc',
    'frzjh',
    'frdh',
    'dz',
    'dlrmc',
    'dlrzjh',
    'dlrdh',
    'zl',
    'name',
    'nameUsedBefore',
    'zjh',
    'phone',
  ],
  '/api/registerflow/modifyObligor': [
    'ywrmc',
    'zjh',
    'dh',
    'dz',
    'dlrmc',
    'dlrdh',
    'dlrzjh',
    'frmc',
    'frdh',
    'frzjh',
    'zl',
    'name',
    'nameUsedBefore',
    'zjh',
    'phone',
  ],
  '/api/registerflow/modifyProcessInfo': [
    'ywrmc',
    'qlrmc',
    'zjh',
    'dh',
    'frmc',
    'frzjh',
    'frdh',
    'dz',
    'dlrmc',
    'dlrzjh',
    'dlrdh',
    'zl',
    'name',
    'nameUsedBefore',
    'zjh',
    'phone',
    'tzrmc',
    'tzdh',
    'dzyx',
    'ygr',
    'nsrsbh',
    'zddm',
    'bdcqzh',
  ],
  '/api/registerflow/modifyRightHolder': [
    'qlrmc',
    'zjh',
    'dh',
    'frmc',
    'frzjh',
    'frdh',
    'dz',
    'dlrmc',
    'dlrzjh',
    'dlrdh',
    'zl',
    'name',
    'nameUsedBefore',
    'zjh',
    'phone',
  ],
  '/api/registerflow/saveQlxxExMh': ['zddm', 'bdcqzh', 'zl', 'kfqymc', 'kfqybm', 'bdcdjzmh', 'tdsyqr'],
  '/thirdServiceController/sendAuthCodeMsg': ['phone'],
  '/userInfoController/authInstitutionalUser': ['qymc', 'qydz', 'qyzjh', 'frmc', 'frzjh', 'frsfszd', 'xm', 'sfzh'],
  '/userInfoController/authUserinfo': ['loginName', 'name', 'zjh'],
  '/userInfoController/checkInstitutionalUser': ['sjrymc', 'bshrymc', 'zl'],
  '/userInfoController/existsIntitutionalUserLoginName': ['loginName'],
  '/userInfoController/existsUserByNameOrPhone': ['keyWord'],
  '/userInfoController/findInstitutionalUser': ['loginName', 'qymc', 'qyzjh', 'frmc'],
  '/userInfoController/findUser': ['loginName'],
  '/userInfoController/findUserName': ['phone'],
  '/userInfoController/findUserinfo': ['loginName', 'name', 'zjh', 'tel', 'dz'],
  '/userInfoController/institutionalApprove': ['frmc', 'frzjh', 'frdh', 'zjh', 'username'],
  '/userInfoController/modifyUserinfoByTel': ['tel', 'authCode', 'passWord', 'passWords'],
  '/userInfoController/modifyUserinfoPhone': ['loginName', 'oriPhone', 'newPhone'],
  '/userInfoController/registerInstitutionalUser': ['lxdh', 'authCode'],
  '/userInfoController/registerUserinfo': ['tel'],
  '/userInfoController/updateFinacialInfo': ['frmc', 'frzjh', 'frdh', 'rsfszd', 'corpName', 'certificateSno'],
  '/userInfoController/updateInstitutionalUser': [
    'xm',
    'sfzh',
    'qydz',
    'qyzjh',
    'loginName',
    'lxdh',
    'frmc',
    'frzjh',
    'frdh',
    'zdxxes',
    'zddm',
    'zl',
    'agents',
    'name',
    'phoneNo',
    'idNo',
    'orgPhone',
  ],
  '/userInfoController/zddmApplyCheckByPage': ['userName', 'zddm'],
  '/userOperatorController/getBackstageDisplay': ['yyyhmc', 'zjh'],
  '/userOperatorController/saveGuestNote': ['lyry', 'lyrylxfs', 'username'],
  '/userOperatorController/saveOrUpdateAddress': ['shr', 'sjhm', 'gddh', 'xxdz', 'dzbm', 'szxqdz', 'mrdz'],
  '/userOperatorController/getGuestNoteList': ['username'],
  '/fileUploadController/appPageList': ['operatorName'],
  '/api/registerflow/copyDyrToZwr': [
    'ywrmc',
    'zjh',
    'dh',
    'dz',
    'dlrmc',
    'dlrdh',
    'dlrzjh',
    'frmc',
    'frdh',
    'frzjh',
    'zl',
    'name',
    'nameUsedBefore',
    'phone',
  ],
  '/api/doorToDoor/finish': [
    'applicantAddr',
    'applicantName',
    'applicantPhone',
    'applicationCode',
    'auditor',
    'certNo',
    'officeHall',
    'officeHallAddr',
    'officeHallPhone',
    'orgName',
    'userId',
    'immovablePropertyNo',
  ],
  '/api/doorToDoor/lookThrough': [
    'applicantName',
    'applicantAddr',
    'applicantPhone',
    'applicationCode',
    'certNo',
    'officeHall',
    'officeHallAddr',
    'officeHallPhone',
    'orgName',
    'auditor',
    'immovablePropertyNo',
  ],
  '/userInfoController/inBlackList': ['loginName'],
  '/api/doorToDoor/listByOptions': ['applicantName', 'applicantPhone'],
  '/api/doorToDoor/initiate': [
    'applicantName',
    'certNo',
    'applicantPhone',
    'applicantAddr',
    'officeHallPhone',
    'officeHallAddr',
  ],
  '/digitalcert/api/download': ['zh', 'yhm'],
  '/digitalcert/api/verify': ['zh', 'yhm'],
  '/digitalcert/api/querycertificatelist': ['qlrmc', 'qlrzjh'],
  '/ems/getOrdersByUserId': ['receiverName', 'senderName'],
  '/payMutualController/saveDuplicatePayApply': ['orgName', 'payerName', 'certNo'],
  '/realEstateQueryController/checkQszm': ['bdcxxList'],
  '/realEstateQueryController/getUncheckedQszmList': ['cxsqr'],
  '/api/registerflow/addTzr': ['tzrmc', 'tzdh'],
  '/userInfoController/getBlackReasonByName': ['loginName'],
  '/userInfoController/getAgentUserList': ['name', 'sfzh'],
  '/userInfoController/deleteUserinfoByLoginname': ['loginName'],
  '/userInfoController/modifyUserinfoPwd': ['oriPassword', 'newPassword', 'loginName'],
  '/userInfoController/generateRandomValue': ['loginName'],
  '/userInfoController/addToBlackList': ['czry', 'czjg'],
  '/userInfoController/updateAgent': ['name', 'phoneNo', 'orgName', 'orgPhone', 'idNo'],
  '/userInfoController/checkSecretKey': ['loginName'],
  '/userInfoController/getAgentUserByQyId': ['name'],
  '/userInfoController/removeFromBlackList': ['czry', 'czjg'],
  '/userInfoController/getTwoDimensionalCode': ['loginName'],
  '/userInfoController/addAgent': ['name', 'phoneNo', 'orgName', 'orgPhone', 'idNo'],
  '/userInfoController/deleteInstitutionalUser': ['loginName'],
  '/userOperatorController/getGuestNoteBySingleQuery': ['username'],
  '/visualQueryRecordController/findById': ['id'],
  '/userInfoController/updateDigitalAgent': ['password'],
  '/registerController/savePrVerificationInfo': ['bdcqzh', 'qlrmc', 'zjh'],
  '/user/page': ['loginName', 'realName'],
  '/articleController/insertArticle': ['mainAuthor', 'mainWriter'],
  '/ca/addInstitutionalUser': ['name', 'xm', 'sfzh', 'qydz', 'qyzjh', 'lxdh', 'frmc', 'frzjh', 'frdh'],
  '/visualQueryRecordController/save': ['name', 'zjh'],
};

export const decrypt = (strMessage, key) => {
  let decryptResult = null;
  let parseResult = null;
  // 不为字符串、空字符串、"WW-"开头的业务号、"411282"开头的不动产单元号不用解密直接返回
  if (
    typeof strMessage !== 'string' ||
    strMessage === '' ||
    strMessage.indexOf('WW-') === 0 ||
    strMessage.indexOf('411282') === 0
  ) {
    return strMessage;
  }
  try {
    decryptResult = crypto.decryptByDES(strMessage, key) || strMessage;
  } catch (err) {
    return strMessage;
  }
  try {
    if (!isNaN(decryptResult) || ['true', 'false', 'undefined', 'null'].includes(decryptResult)) {
      parseResult = decryptResult;
    } else {
      parseResult = JSON.parse(decryptResult);
    }
  } catch (err) {
    parseResult = decryptResult;
  }
  return parseResult;
};

export const traverseDecrypt = (data, key) => {
  if (typeof data === 'string') {
    data = decrypt(data, key);
  } else if (Array.isArray(data)) {
    data.forEach((item) => {
      if (Object.prototype.toString.call(item) === '[object Object]') {
        traverseDecrypt(item, key);
      }
    });
  } else if (Object.prototype.toString.call(data) === '[object Object]') {
    Object.keys(data).forEach((k) => {
      if (typeof data[k] === 'string') {
        data[k] = decrypt(data[k], key);
      } else {
        traverseDecrypt(data[k], key);
      }
    });
  }
  return data;
};

// 判断加密的字段是否为特殊字段
const isSpecificField = (url, field) => {
  return Object.entries(encryptWhiteInfo).some(([key, value]) => url.includes(key) && value.includes(field));
};

//接口参数加密
export function encryptUrlDataByDes(url, urlData = {}) {
  if (typeof urlData === 'object') {
    for (const iterator in urlData) {
      if (typeof urlData[iterator] === 'object') {
        if (
          isSpecificField(url, iterator) &&
          Array.isArray(urlData[iterator]) &&
          urlData[iterator].length > 0 &&
          typeof urlData[iterator][0] === 'string'
        ) {
          urlData[iterator] = urlData[iterator].map((item) => crypto.encryptByDES(item));
        } else {
          encryptUrlDataByDes(url, urlData[iterator]);
        }
      } else if (urlData[iterator] && typeof urlData[iterator] === 'string' && isSpecificField(url, iterator)) {
        urlData[iterator] = crypto.encryptByDES(urlData[iterator]);
      }
    }
  }
  return urlData;
}
//接口参数解密
export function decryptUrlDataByDes(resData) {
  try {
    resData.data = crypto.decryptByDES(resData.data) || resData.data;
    if (typeof resData.data === 'string') {
      resData.data = JSON.parse(resData.data);
    }
  } catch {}
  return resData;
}
