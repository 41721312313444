<template>
  <div class="wrapper">
    <!-- 证件类型为身份证 -->
    <div class="content" v-if="checkData[0].zjzl == 1 && checkData[0].qlrlx == 1">
      <div>
        <h4 class="el-icon-postcard">公安部门身份信息核验</h4>
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="核验结果" prop="checkPoliceResult">
            <el-input placeholder="核验结果" disabled v-model="checkPoliceResult"></el-input>
          </el-form-item>
          <el-form-item label="核验时间" prop="checkPoliceTime">
            <el-input placeholder="核验时间" disabled v-model="checkPoliceTime"> </el-input>
          </el-form-item>
        </el-form>
      </div>
      <div>
        <h4 class="el-icon-postcard">民政部门登记信息核验</h4>
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="核验结果">
            <el-input placeholder="核验结果" disabled v-model="checkMzResult"></el-input>
          </el-form-item>
          <el-form-item label="核验时间">
            <el-input placeholder="核验时间" disabled v-model="checkMzTime"> </el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 证件类型为统一社会信用代码 -->
    <div class="content" v-if="tyCodeShow">
      <div>
        <h4 class="el-icon-postcard">{{ checkTitle }}统一社会信用代码核验</h4>
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="核验结果">
            <el-input placeholder="核验结果" disabled v-model="checkPoliceResult"></el-input>
          </el-form-item>
          <el-form-item label="核验时间">
            <el-input placeholder="核验时间" disabled v-model="checkPoliceTime"> </el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import applicantOperatorService from '@/pages/iebdc/api/apply/applicant-operator';
import { localDataUser } from '@iebdc/utils/local-data';
// import sm4 from '@iebdc/utils/gm-crypto.js';

export default {
  name: 'Index',
  components: {},
  props: {
    checkTitle: {
      type: String,
      default: () => '',
    },
    checkData: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    checkData: {
      immediate: true,
      handler(newVal) {
        this.firstEnter();
      },
    },
  },
  data() {
    this.userInfo = localDataUser.get();
    console.log(this.userInfo);
    return {
      checkPoliceResult: '',
      checkPoliceTime: '',
      checkMzResult: '',
      checkMzTime: '',
      // familyMemberList: checkData[0].familyGroup.familyMemberList.length,
      tyCodeShow: Boolean,
      checkParams: {
        dto: {
          queryParam: {
            QQRGMSFHM: this.$decryptByDES(this.userInfo.zjh), // 请求人身份证号码
            QQRXM: this.$decryptByDES(this.userInfo.name), // 请求人姓名
            GMSFHM: '', // 被核验人身份证号码
            XM: '', // 被核验人姓名
          },
          requestType: 'GA01',
          deptName: '',
          userName: '',
          cxqqdh: '',
          businessNumber: '',
          xzqh: '',
        },
      },
    };
  },
  computed: {},
  created() {
    // console.log(this.checkData);
    // console.log('证件类型' + this.checkData[0].zjzl);
    // console.log('申请人类型' + this.checkData[0].qlrlx);
    // 统一社会信用代码+事业单位 统一社会信用代码+国家机关
    // this.firstEnter();
    // this.familyMemberLength = this.checkData.familyGroup.familyMemberList.length;
  },
  mounted() {},
  methods: {
    firstEnter() {
      if (this.checkData[0].zjzl == 8 && (this.checkData[0].qlrlx == 3 || this.checkData[0].qlrlx == 4)) {
        this.tyCodeShow = true;
      } else {
        this.tyCodeShow = false;
      }
      // 核验类型
      if (this.checkData[0].zjzl == 1 && this.checkData[0].qlrlx == 1) {
        // 公安及民政核验
        this.getCheck(1);
      } else if (this.checkData[0].zjzl == 8 && this.checkData[0].qlrlx == 3) {
        //事业单位
        this.getCheck(2);
      } else if (this.checkData[0].qlrlx == 4) {
        //党群机关
        this.getCheck(3);
      }
    },
    getCheck(checkType) {
      console.log(this.checkData);
      if (checkType == 1) {
        // 民政
        this.mzCheck();
        this.getPolice();
      } else if (checkType == 2) {
        this.checkParams.dto.requestType = 'ZB02'; // 事业单位
        this.checkParams.dto.queryParam = {
          name: this.checkData[0].qlrmc,
          tyshxydm: this.checkData[0].zjh,
        };
        this.getBwgx();
      } else if (checkType == 3) {
        this.checkParams.dto.requestType = 'ZB01'; // 党群机关
        this.checkParams.dto.queryParam = {
          name: this.checkData[0].qlrmc,
          tyshxydm: this.checkData[0].zjh,
        };
        this.getBwgx();
      }
    },
    // 民政部门核验
    mzCheck() {
      this.checkParams.dto.requestType = 'MZ01'; // 民政
      this.checkParams.dto.queryParam.XM = this.checkData[0].qlrmc ? this.checkData[0].qlrmc : this.checkData[0].ywrmc;
      this.checkParams.dto.queryParam.GMSFHM = this.checkData[0].zjh;
      // this.getBwgx();
      console.log(this.checkParams);
      let _checkParams = this.$encryptByDES(JSON.stringify(this.checkParams));
      applicantOperatorService.getBwgxInfo(_checkParams, { dto: _checkParams }).then((res) => {
        if (res.success) {
          this.checkMzTime = res.data.receiveTime;
          this.checkMzResult = res.data.rtnMessage;
        } else {
          this.$message.warning('核验信息失败，请重新保存信息核验');
        }
      });
    },
    // 公安
    getPolice() {
      // this.checkParams.dto.requestType = 'GA01'; // 公安
      // this.checkParams.dto.queryParam.XM = this.checkData[0].qlrmc;
      // this.checkParams.dto.queryParam.GMSFHM = this.checkData[0].zjh;
      const checkParams = {
        dto: {
          queryParam: {
            QQRGMSFHM: this.$decryptByDES(this.userInfo.zjh), // 请求人身份证号码
            QQRXM: this.$decryptByDES(this.userInfo.name), // 请求人姓名
            GMSFHM: this.checkData[0].zjh, // 被核验人身份证号码
            XM: this.checkData[0].qlrmc ? this.checkData[0].qlrmc : this.checkData[0].ywrmc, // 被核验人姓名
          },
          requestType: 'GA01',
          deptName: '',
          userName: '',
          cxqqdh: '',
          businessNumber: '',
          xzqh: '',
        },
      };
      console.log(checkParams);
      let _checkParams = this.$encryptByDES(JSON.stringify(checkParams));
      applicantOperatorService.getBwgxInfo(_checkParams, { dto: _checkParams }).then((res) => {
        if (res.success) {
          this.checkPoliceTime = res.data.receiveTime;
          this.checkPoliceResult = res.data.rtnMessage;
        } else {
          this.$message.warning('核验信息失败，请重新保存信息核验');
        }
      });
    },
    // 部委共享核验接口
    getBwgx() {
      console.log(this.checkParams);
      let _checkParams = this.$encryptByDES(JSON.stringify(this.checkParams));
      applicantOperatorService.getBwgxInfo(_checkParams, { dto: _checkParams }).then((res) => {
        if (res.success) {
          console.log(res);
          this.checkPoliceTime = res.data.receiveTime;
          this.checkPoliceResult = res.data.rtnMessage;
          if (this.checkParams.dto.requestType === 'MZ01') {
            this.checkMzTime = res.data.receiveTime;
            this.checkMzResult = res.data.rtnMessage;
          }
        } else {
          this.$message.warning('核验信息失败，请重新保存信息核验');
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  padding: 0 0 0 10px;
  margin: 0 !important;
}
h4 {
  font-weight: 600;
  margin: 15px 0;
}
.el-icon-postcard:before {
  color: #2c8bff;
  margin-right: 10px;
}
</style>
